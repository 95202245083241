import React, { useState, useEffect} from 'react';
import axios from 'axios';
import { sidebarStyle } from '../style/sideBarStyle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Mail2U from '../components/mail2U';
import { Link } from "gatsby"

const images = require.context('../images/weather', true);

const SideBar = (props) => {
  const [fetchUrl, setFetchUrl] = useState("")
  const urlString = `/results?q=${fetchUrl}`
  const hideOnMobile = useMediaQuery('(min-width:821px)');
  const [data, setData] = useState([]);
  //const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [weatherIcon, setWeatherIcon] = useState(images("./blank.gif"));

  
  const handleSubmit = (event) => {
    event.preventDefault();
    window.location.href=urlString;
  }

  let Class="col-6-12 indexrightsidefont";
  if (props.FullWidth)
    Class="indexrightsidefont"

  useEffect(() => {
    const fetchData = async () => {
      setIsError(false)
     // setIsLoading(true)
      try {
        const result = await axios(process.env.GATSBY_SIDEBAR_API)
        setData(result.data)
        setWeatherIcon(images('./'+result.data.weatherIcon));
      } catch (error) {
        setIsError(true)
        console.log(error);
      }
       // setIsLoading(false)
    }
      fetchData();
  },[])

  if (isError) return <div><b>data is currently unavailable</b></div>;
  return (
    <React.Fragment>
        <div css={sidebarStyle} id="sidebar" className={Class}>
        
        <div id="tools">
          <div id="search">
          <form onSubmit={handleSubmit} >
            <label htmlFor="searchBox"><h2 id="searchTitle">Search This Site</h2></label>
            <input
              name="searchBox"
              type="text"
              id="searchBox"
              className="search"
              style={{ height: "23px", marginRight:"3px"}}
              onChange={(event) => setFetchUrl(event.target.value)}
            />
            <input
              type="submit"
              className="searchSubmit"
              value=""
              alt="Search"
            />
            </form>
          </div>

          <div id="arrivals">
            <h2>
              <Link
                to="/jerseyharbours/arrivals-departures"
              >
                Arrivals &amp; Departures
              </Link>
            </h2>
            <ul>
              <li className="arrive">
                <strong>Arrived</strong>
                <br />
                {data.Arrived}
              </li>
              <li className="depart">
                <strong>Departed</strong>
                <br />
                {data.Departed}
              </li>
            </ul>
          </div>

          <div id="tides">
            <h2>
              <a href="/tides" title="Tides at St Helier">
                Tides at St Helier
              </a>
            </h2>
            <ul>
              <li dangerouslySetInnerHTML={{ __html:data.nextTide }}></li>
              <li dangerouslySetInnerHTML={{ __html:data.futureTide }}></li>
            </ul>
            {/* <Link to="/weather#tidedata" title="Tides at St Helier">
              <p className={data.marinaStatus==="open" ?  "gatesOpen" :  "gatesClosed"}>
                Marina gates {data.marinaStatus}
                <br />
                ({data.marinaStatus==="open" ?  "closing" :  "opening"} {data.marinaTime})
              </p>
            </Link> */}
          </div>

          <div id="weather">
            <h2>
              <Link
                to="/weather"
                title="Ports of Jersey - Five day forecast"
              >
                Weather and <span>forecasts</span>
              </Link>
            </h2>
            <p>
              <strong>{data.weatherDate}</strong>
            </p>

            <div className="today">
              <Link
                to="/weather"
                title="Ports of Jersey - Five day forecast"
              >
                <img src={weatherIcon} alt="" />
              </Link>
              <p>
                max
                <br />
                <span className="temperature">{data.weatherMaxTemp}°c</span>
              </p>
            </div>
          </div>

          {hideOnMobile && <Mail2U />}
          
        </div>
      </div>
    </React.Fragment>
  )
}
export default SideBar
