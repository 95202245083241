import { css } from "@emotion/core"


export const sectionLinksStyle = css`
ul.navLeaders li h2, ul.navLeadersSub li h2 {
    margin: 0.5em 0 0 0;
    font-size: 14px;
    display: inline;
    line-height: 24px;
}
 #sectionPages ul.navLeaders li img{
    max-width:135px;
}
`
