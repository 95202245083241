import React from "react"
import { sectionLinksStyle } from '../style/sectionLinksStyle';
import Links from '../components/Links'
import Excerpt from "./excerpt-coastguard";
import ExcerptA from "./excerpt-airport";
import ExcerptH from "./excerpt-harbour";
import ExcerptM from "./excerpt-marina";
import ExcerptP from "./excerpt-port";
const SectionLinks = (props) => {

  return (
    <React.Fragment>
      <div css={sectionLinksStyle} id="section">
        <div id="sectionPages" className="noindex indexrightsidefont">
         {props.SectionLinks.length >0 && 
            <React.Fragment>
              <ul className="navLeaders">
         {props.Mode ==="coastguard"&&
         <React.Fragment>
                 {props.SectionLinks.map( link => link.imageurl===""?<li key={link.id}><h2><Links to={link.url} name={link.title}/></h2><Excerpt pagelink={link.url}></Excerpt></li>:<li key={link.id}><Links to={link.url} name="img" img={link.imageurl}/><h2><Links to={link.url} target={link.target} name={link.title}/></h2><Excerpt pagelink={link.url}></Excerpt></li>)}
                 </React.Fragment>
        }
         {props.Mode ==="port"&&
         <React.Fragment>
                 {props.SectionLinks.map( link => link.imageurl===""?<li key={link.id}><h2><Links to={link.url} name={link.title}/></h2><ExcerptP pagelink={link.url}></ExcerptP></li>:<li key={link.id}><Links to={link.url} name="img" img={link.imageurl}/><h2><Links to={link.url} target={link.target} name={link.title}/></h2><ExcerptP pagelink={link.url}></ExcerptP></li>)}
                 </React.Fragment>
        }
         {props.Mode ==="airport"&&
         <React.Fragment>
                 {props.SectionLinks.map( link => link.imageurl===""?<li key={link.id}><h2><Links to={link.url} name={link.title}/></h2><ExcerptA pagelink={link.url}></ExcerptA></li>:<li key={link.id}><Links to={link.url} name="img" img={link.imageurl}/><h2><Links to={link.url} target={link.target} name={link.title}/></h2><ExcerptA pagelink={link.url}></ExcerptA></li>)}
                 </React.Fragment>
        }
         {props.Mode ==="marina"&&
         <React.Fragment>
                 {props.SectionLinks.map( link => link.imageurl===""?<li key={link.id}><h2><Links to={link.url} name={link.title}/></h2><ExcerptM pagelink={link.url}></ExcerptM></li>:<li key={link.id}><Links to={link.url} name="img" img={link.imageurl}/><h2><Links to={link.url} target={link.target} name={link.title}/></h2><ExcerptM pagelink={link.url}></ExcerptM></li>)}
                 </React.Fragment>
        }
         {props.Mode ==="harbour"&&
         <React.Fragment>
                 {props.SectionLinks.map( link => link.imageurl===""?<li key={link.id}><h2><Links to={link.url} name={link.title}/></h2><ExcerptH pagelink={link.url}></ExcerptH></li>:<li key={link.id}><Links to={link.url} name="img" img={link.imageurl}/><h2><Links to={link.url} target={link.target} name={link.title}/></h2><ExcerptH pagelink={link.url}></ExcerptH></li>)}
                 </React.Fragment>
        }
              </ul>
            </React.Fragment>
          }            
        </div>
      </div>
    </React.Fragment>
  )
}
export default SectionLinks
