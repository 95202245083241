
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Links from '../components/Links'
const Furtherpage = () => {
    var path= "";
    const data = useStaticQuery(graphql`
    query SitePagesQuery {
      wpgraphql {
        menus{
            nodes {
              id
              name
              menuItems {
              
                  nodes {
                    id
                    url
                    label
                    childItems {
                        nodes {
                          id
                          url
                          label
                        }
                      }
                    }
                  }
                }
              }
            }
    }
  `);
    if (typeof window !== 'undefined') {
        path=window.location.pathname;
        var lastIndex = path.lastIndexOf('/');
        if(lastIndex ===path.length-1)
        {
            path = path.substring(0,lastIndex-1)
            lastIndex = path.lastIndexOf('/');
        }
        path = path.substring(0,lastIndex)
    }

    if(path==="/jerseycoastguard"||path==="/jerseymarinas"||path==="/jerseyairport"||path==="/"||path==="/jerseyharbours"||path==="")
    {
        return "";
    }
    else{
    let ports = false;
    let harbours = false;
    let marinas = false;
    let coastguard = false;
    let airport = false;
  
    if (typeof window !== 'undefined') {
      if (path.indexOf("jerseycoastguard") !== -1)
        coastguard=true;
      else if (path.indexOf("jerseymarinas") !== -1)
        marinas=true;
      else if (path.indexOf("jerseyharbours") !== -1)
        harbours=true;
      else if (path.indexOf("jerseyairport") !== -1)
        airport=true;
      else
        ports=true;
    }
   
    if(process.env.GATSBY_SITE_MODE==="AIRPORT"){
      airport=true;
      ports=false;
    }
      
   
    var mainMenu=[];
    //var pagename=""
    var futherpages=[];


    data.wpgraphql.menus.nodes.forEach(node => {
        if (node.name==="Ports Main Menu" && ports===true)
            mainMenu = node.menuItems.nodes;
        if (node.name==="Harbours Main Menu" && harbours===true)
            mainMenu = node.menuItems.nodes;
        if (node.name==="Marinas Main Menu" && marinas===true)
            mainMenu = node.menuItems.nodes;
        if (node.name==="Coastguard Main Menu" && coastguard===true)
            mainMenu = node.menuItems.nodes;
        if (node.name==="Airport Main Menu" && airport===true)
            mainMenu = node.menuItems.nodes;
      })
      mainMenu.forEach(node => {
       if(node.url.indexOf(path.toLowerCase()+"/") > -1)
        {
              //pagename=node.label.replace("&#038;","&").replace("&#8217;","'");      
              node.childItems.nodes.forEach(childnode=>{
                if (typeof window !== 'undefined') {
                  if(childnode.url.indexOf(window.location.pathname.toLowerCase()) < 0)
                    futherpages.push({"id":childnode.id,"name":childnode.label,"url":childnode.url})  
                } 
              }
            )
        }
      })
    

      if(futherpages.length!==0)
      {    
        return (
        <div id="furtherPage" className="noindex indexrightsidefont">
        <h2>In this section</h2>
        <ul className="navFurther">   
        {futherpages.map(( node ) => (<li key={node.id}><Links to={node.url} name={node.name}></Links></li>))}  
        </ul>
        </div>
        )
    }    
    else return "";
    }
}
export default Furtherpage