import React from "react"
import { useStaticQuery,graphql } from "gatsby"
var striptags = require('striptags');

const ExcerptA = (props) => {
  var output ="";
  const allPages = [];

  const data =useStaticQuery(graphql`
  query GetAirPorts{
  wpgraphql {
    airports(first: 100) {
      nodes {
        id
        title
        excerpt
        link
      }
    }
   
    }
    }
  `);

  if (typeof props.pagelink !== 'undefined') {
    if (props.pagelink==="/jerseyairport/news/") {
      output="<p>Catch up with the latest news from Jersey Airport.</p>";  
    }
  }
    
  if(!data){ return }
    else{ 
            
            if (typeof props.pagelink !== 'undefined') {
              data.wpgraphql.airports.nodes.forEach(node => {
              if (props.pagelink==="/news/") {
                output="Catch up with the latest news from Jersey Airport.";  
                return;
              }
              if (node.link.replace("http://"+process.env.GATSBY_WORDPRESS_PREVIEW,"").replace("https://"+process.env.GATSBY_WORDPRESS_PREVIEW,"") === props.pagelink.toLowerCase()+"/" || node.link.replace("http://"+process.env.GATSBY_WORDPRESS_PREVIEW,"").replace("https://"+process.env.GATSBY_WORDPRESS_PREVIEW,"") === props.pagelink.toLowerCase()){
                allPages.push({"text":node.excerpt});
                output=allPages[0].text;  
                return;
              }
            })
          };
      
  }
return <span dangerouslySetInnerHTML={{ __html: striptags(output, '<p>') }}></span>;
}
 
export default ExcerptA