import Modal from "@material-ui/core/Modal"
import React from "react"
import Button from "@material-ui/core/Button"
import { makeStyles } from '@material-ui/core/styles';


function getModalStyle() {
    const top = 50;
    const left = 50;
    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        position: 'absolute',
        width: 450,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

const ModalImages = (props) => {
    const classes = useStyles();
    const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = React.useState(false);
     const handleOpen = () => {
      setOpen(true);
  };

  const handleClose = () => {
      setOpen(false);
  };
    return (
        <div id="GalleryImages">
          <Button onClick={handleOpen}>
               <img src={encodeURI(props.src)} alt="" title={props.title} />
            </Button>

            <Modal
                open={open}
                onClose={handleClose}
            >
 <div style={modalStyle} className={classes.paper}>
                <img className="fullImage" src={encodeURI(props.src)} alt="" title={"enlarge image "+ props.title} />
                <button className="btn_close" onClick={handleClose}>Close [X]</button>
                </div>
            </Modal>
        </div>)
}
export default ModalImages