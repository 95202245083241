import React from "react"
import Furtherpage from '../components/furtherpages';
import { relatedLinkStyle } from '../style/relatedLinksStyle';
import Links from '../components/Links';
import Modal from '../components/Modal';
const RelatedLinks = (props) => {
  return (
    <div css={relatedLinkStyle} id="related" className="col-6-12">
     
    {(props.Template!=="Landing")&& 
    <React.Fragment>
  
                   <Furtherpage />
                  
         {props.RelatedPages.length > 0 && 
          <React.Fragment>
          <h2>Related Pages</h2>
          <ul className="pages">
            {props.RelatedPages.map((link,index) => <li key={index}><Links to={link.url} name={link.title}/></li>)}
          </ul>
          </React.Fragment>
          }
          </React.Fragment>
        }
          {props.RelatedLinks.length > 0 && 
            <React.Fragment>
              <h2>Related Links</h2>
              <ul className="links">
                {props.RelatedLinks.map((link, index) => <li key={index}><Links to={link.url} name={link.title}/></li>)}
              </ul>
            </React.Fragment>
          }
           {props.RelatedDocuments.length > 0 &&      
            <React.Fragment>
          <h2>Related Documents</h2>
            <ul className="docs">
            {props.RelatedDocuments.map((link, index) => <li key={index}><Links to={link.url} name={link.title}/></li>)}
            </ul>
            </React.Fragment>
           }
         {(props.Template!=="Landing")&& 
          <React.Fragment>             
            {props.RelatedImages.length > 0 && 
            
              <React.Fragment> 
                <div id="gallery">

                  {props.RelatedImages.map(link => <Modal src={link.src} title={link.title}/>)} 

                </div>
              </React.Fragment>
              }
              </React.Fragment>
        }
        
          <div className="video"></div>
          
    
    </div>

  )
}
export default RelatedLinks

